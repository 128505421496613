
html, body {
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor:none;
}

section,
footer,
header,
div,
span,
p,
img {
  margin: 0;
  padding: 0;
  cursor:none;
}

input,
select,
button {
  cursor:none;
}

