.basic-d {
    width: 135px;
    display: flex;
    align-items: center;
    .ant-picker {
        font-family: Poppins4;
        border-radius: 16px;
        background: #FFC600;
    }

    .ant-picker-input {
        margin-left: 14px;
    }
}