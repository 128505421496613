$primary-color: #5552FF;
$margin-out: 0 10px 12px;
$padding: 15px 0 15px 15px;

@mixin margin-out {
    margin: 0 10px 12px;
}

@mixin padding-in {
    padding: 15px 0 15px 15px;
}

@font-face {
    font-family: 'Poppins4';
    src: url("../../assets/font/Poppins-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins5';
    src: url("../../assets/font/Poppins-Medium.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins6';
    src: url("../../assets/font/Poppins-SemiBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins7';
    src: url("../../assets/font/Poppins-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

.wrep {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    max-width: 430px;

    :where(.css-dev-only-do-not-override-1gwfwyx).ant-image {
        width: 100%;
        display: block;
    }

    :where(.css-1gwfwyx).ant-image {
        width: 100%;
        display: block;
    }

    .dash {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 15px 10px 15px;

        .dash-p {
            font-family: Poppins6;
            font-size: 24px;
            font-weight: 600;
            line-height: 28.8px;
            text-align: left;
        }

        .dash-d {
            width: 135px;

            .ant-picker {
                font-family: Poppins4;
                border-radius: 16px;
                background: #FFC600;
            }

            .ant-picker-input {
                margin-left: 14px;
            }
        }
    }

    .last {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include margin-out;

        margin-top: 10px;
        padding: 0 15px;
        height: 50px;
        gap: 0px;
        border-radius: 16px;
        border: 1px solid #5552FF99;

        .last-l {
            color: #111;
            font-family: Poppins4;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .last-r {
            color: #111;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .level {
        flex-shrink: 0;
        border-radius: 16px;
        background: #5552FF;

        @include margin-out;

        padding: $padding;
        position: relative;

        .help {
            position: absolute;
            right: 8px;
            top: 4px;
        }

        .explain {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .explain-l {
                width: 221px;
                color: #FFF;
                font-family: Poppins4;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 15px; /* 150% */
            }

            .explain-r {
                width: 120px;
                height: 117px;
            }
        }
    }

    .goal {
        @include margin-out;

        .title {
            flex-shrink: 0;
            background-color: #5552FF;
            border-radius: 16px 16px 0 0;
            color: #FFF;
            font-family: Poppins6;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;

            @include padding-in;
        }

        .content {
            // height: 200px;
            fill: #FFF;
            border: 1px solid #EEE;
            border-radius: 0 0 16px 16px;
            padding: 15px;

            .text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #333;
                margin-bottom: 13px;

                .text-p {
                    font-family: Poppins7;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .text-p0 {
                    font-family: Poppins6;
                }
            }

            .process {
                width: calc(100%);
                height: 4px;
                flex-shrink: 0;
                border-radius: 10px;
                background: #D7D7D7;
                display: flex;
                align-items: center;
                position: relative;

                .process-s {
                    height: 100%; // 使进度条的高度与父元素一致
                    z-index: 1;
                    border-radius: inherit; // 使进度条的边角与父元素一致
                    margin: 0;
                    background: linear-gradient(90deg, #5552FF, #0A07B1);
                    position: relative; // 确保伪元素相对于这个元素定位

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: repeating-linear-gradient(-45deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.2) 2px, transparent 5px, transparent 10px); // 斜条纹，透明度和宽度可以调整
                   
                    }
                }

                .process-s2 {
                    height: 100%; // 使进度条的高度与父元素一致
                    z-index: 1;
                    border-radius: inherit; // 使进度条的边角与父元素一致
                    margin: 0;
                    background: linear-gradient(90deg, #FFC600, #E95D00);
                    position: relative; // 确保伪元素相对于这个元素定位

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: repeating-linear-gradient(-45deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.2) 2px, transparent 5px, transparent 10px); // 斜条纹，透明度和宽度可以调整
                   
                    }
                }

                .process-p {
                    width: 30px;
                    flex-shrink: 0;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: #FFF;
                    margin-left: -1px;
                    z-index: 1;
                    border-radius: 9px;
                    background: #3330DC;
                }

                .process-p2 {
                    background: #E95D00;
                }
            }
        }
    }

    .base {
        @include margin-out;

        .base-title {
            display: flex;
            justify-content: space-between;
            height: 64px;
            flex-shrink: 0;
            background-color: #5552FF;
            border-radius: 16px 16px 0 0;
            color: #FFF;
            padding: 0 15px;

            .btn {
                height: 42px;
                margin: 11px 0;
                border-radius: 16px;
                color: #5552FF;
                font-family: Poppins6;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                cursor: none;
            }

            .active {
                .ant-select-selector {
                    font-family: Poppins5;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.12px;
                    background-color: #FFF;

                    .ant-select-selection-item {
                        font-family: Poppins5;
                        color: #5552FF;
                        margin-left: 8px;
                        font-size: 12px;
                    }
                }
            }

            

            :where(.css-dev-only-do-not-override-1gwfwyx).ant-select-single {
                height: auto;
            }

            .ant-select-selector {
                height: 42px;
                // margin: -3px 0;
                border-radius: 16px;
                background: none;
                color: #FFF;
                display: flex;
                align-items: center;
                padding-left: 20px;

                .ant-select-selection-item {
                    font-family: Poppins5;
                    color: #FFF;
                    margin-left: 8px;
                    font-size: 12px;
                }
            }

            .ant-select-arrow {
                color: #FFF;
                top: 50%;
            }
        }

        .mask{
            position: absolute;
            height: 100%;
            background-color: #EEE; /* 灰色透明背景 */
            
            .mask-load{
                position: absolute; /* 使元素脱离文档流 */
                top: 80px;
                left: 50%;
                transform: translate(-50%, -50%); /* 使元素居中 */
            }
        }
        .base-content {
            position: relative;
            fill: #FFF;
            border: 1px solid #EEE;
            border-radius: 0 0 16px 16px;
            padding: 15px;
            margin-bottom: 80px;
            // background-color: rgba(0, 0, 0, 0.5); /* 灰色透明背景 */

            .pp {
                color: #5552FF;
                margin-bottom: 15px;
                font-family: Poppins7;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 15px 27px;

                .item-t {
                    color: #666;
                    font-family: Poppins4;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 10px;
                }

                .item-v {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-family: Poppins6;
                    font-weight: 600;
                    line-height: normal;
                    height: 35px;
                    border-radius: 17.5px;
                    background: #F1F5F6;
                    padding: 0px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .item-per {
                        color: #5552FF;
                        text-align: right;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.151px;
                    }
                }
            }

            .footer {
                font-family: Poppins4;
                color: #999;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 15px 11px 0px;
            }
        }
    }
}

.model_name {
    color: #333;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

h3 {
    font-family: Poppins6;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    padding-left: 2px;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-select-dropdown .ant-select-item-option {
    padding-left: 2px;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-image {
    width: 100%;
    display: block;
}

.ant-image {
    width: 100%;
    display: block;
}

.ant-select-selection-search-input {
    cursor: auto;
}

.ant-picker-now-btn {
    cursor: auto;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    cursor: auto;
}

.ant-select-single {
    height: auto;
}
